import React from 'react';

import NinjaVideo from './videos/Ninja-coder-final.mp4';
import NinjaThumbnail from './images/ninja-thumbnail.png';

export default () => {
    return (
        <>
            <video width="800" controls poster={NinjaThumbnail}>
                <source src={NinjaVideo} type="video/mp4" />
            </video>
        </>
    );
};
