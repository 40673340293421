import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const detectLocale = () => {
    let locale = cookies.get('locale');

    if (locale) {
        return locale;
    }

    if (!global.navigator) {
        return 'en';
    }

    // Code from: https://github.com/maxogden/browser-locale
    if (navigator.languages && navigator.languages.length) {
        // latest versions of Chrome and Firefox set this correctly
        locale = navigator.languages[0];
    } else if (navigator.userLanguage) {
        // IE only
        locale = navigator.userLanguage;
    } else {
        // latest versions of Chrome, Firefox, and Safari set this correctly
        locale = navigator.language;
    }

    if (!locale) {
        return 'en';
    }

    if (locale.indexOf('-') > -1) {
        return locale.substr(0, locale.indexOf('-'));
    }

    return locale;
};

export const setLocale = locale => {
    cookies.set('locale', locale);
};
